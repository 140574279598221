import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';
import Colors from 'assets/Style/Colors';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PrimaryButton } from 'components/Buttons';
import { CloudDownload } from '@mui/icons-material';
import moment from 'moment';
import { Images } from 'assets';
import Footer from 'layouts/Dashboard/Footer';
import Header from 'layouts/Dashboard/Header';
import BillServices from 'services/Bill';
import { ErrorToaster } from 'components/Toaster';
import { CircleLoading } from 'components/Loader';
import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';

function BillDetail() {

  const [searchParams] = useSearchParams();
  const reportTemplateRef = useRef(null);

  // *For Loading
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  // *For Bill Detail
  const [billDetail, setBillDetail] = useState();

  // *For Get Bill Detail By Bill ID
  const getBillDetailById = async (billId) => {
    setLoader(true)
    try {
      let obj = {
        id: billId
      }
      const { data } = await BillServices.getBillDetailById(obj)
      setBillDetail(data)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  // *For Merge PDF
  const mergePdfs = async (existingPdfBytes, newPdfBytes) => {
    // Load existing PDF
    const existingPdf = await PDFDocument.load(existingPdfBytes);

    // Load new PDF
    const newPdf = await PDFDocument.load(newPdfBytes);

    // Create a new PDFDocument
    const mergedPdf = await PDFDocument.create();

    // Copy pages from new PDF
    const [newPdfPage] = await mergedPdf.copyPages(newPdf, newPdf.getPageIndices());
    mergedPdf.addPage(newPdfPage);

    // Copy pages from existing PDF
    const [existingPdfPage] = await mergedPdf.copyPages(existingPdf, existingPdf.getPageIndices());
    mergedPdf.addPage(existingPdfPage);

    // Serialize the merged PDF to bytes
    const mergedPdfBytes = await mergedPdf.save();
    return mergedPdfBytes;
  };

  // *For Generate PDF
  const handleGeneratePdf = async () => {
    setLoading(true)
    try {
      const canvas = await html2canvas(reportTemplateRef.current, { scale: 2, scrollX: 0, scrollY: 0 });
      const imgData = canvas.toDataURL('image/png', 1.0);

      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      const newPdfBytes = pdf.output('arraybuffer');

      // Load the existing PDF from local storage
      const existingPdfBytes = await fetch(Images.kgcRules).then(res => res.arrayBuffer());

      // Merge PDFs
      const mergedPdfBytes = await mergePdfs(existingPdfBytes, newPdfBytes);

      // Save the merged PDF
      const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

      saveAs(blob, `Member_Id_${billDetail?.member_id}_${moment(billDetail?.bill_uplaoded_date).format('MMMM_YYYY')}`);

    } catch (error) {
      console.log('handleGeneratePdf => error:', error)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    const billId = searchParams.get('b')
    getBillDetailById(atob(billId))
  }, []);

  return (
    <Box sx={{ backgroundImage: `url(${Images.authBanner})`, width: 1, minHeight: '100vh', height: '100%', backgroundSize: 'cover', }}>

      {/* ========== Header ========== */}
      <Header showSideDrawer={false} />

      {/* ========== Main ========== */}
      <Box component="main">
        <Container>
          <Box sx={{ bgcolor: Colors.white, p: 2, my: 1, borderRadius: 2, boxShadow: 'rgb(145 158 171 / 16%) 0px 4px 8px 0px' }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item md={6}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  Bill Detail
                </Typography>
              </Grid>
              <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                <PrimaryButton
                  title="Download PDF"
                  loading={loading}
                  btnColor={Colors.secondary}
                  startIcon={<CloudDownload />}
                  onClick={(e) => handleGeneratePdf()}
                />
              </Grid>
            </Grid>
          </Box>

          {loader ? (
            <CircleLoading />
          ) : (
            <Box sx={{ bgcolor: Colors.white, p: 1.5, pb: 1, my: 1, borderRadius: 2, boxShadow: 'rgb(145 158 171 / 16%) 0px 4px 8px 0px' }} >
              <Box ref={reportTemplateRef} sx={{ width: 'auto', mx: 'auto', overflow: 'auto' }}>
                <table width="900" border="0" cellspacing="0" cellpadding="0" align='center' className="normal template-table">
                  <tr>
                    <td align="center" valign="top">
                      <img src={Images.logo} alt="" width="90" height="100" />
                    </td>
                    <td rowspan="2" valign="top" align="right">
                      <table width="100%" border="0" cellspacing="0" cellpadding="0" className="normal">
                        <tr>
                          <td>Telephone:#</td>
                          <td align="center">:</td>
                          <td>021-99240200, 99240201</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td align="center">:</td>
                          <td>(Ext-307 Accounts)</td>
                        </tr>
                        <tr>
                          <td>Fax</td>
                          <td align="center">:</td>
                          <td>021-99240251</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td align="center">:</td>
                          <td><a className="table-link" href="mailto:info@karachigolf.com.pk">info@karachigolf.com.pk</a></td>
                        </tr>
                        <tr>
                          <td>Web</td>
                          <td align="center">:</td>
                          <td><a className="table-link" href="http://karachigolf.com.pk" target="_blank" rel="noreferrer">www.karachigolf.com.pk</a></td>
                        </tr>
                        <tr>
                          <td>NTN #</td>
                          <td align="center">:</td>
                          <td>0891225-4</td>
                        </tr>
                        <tr>
                          <td>S. Tax Rtn.#</td>
                          <td align="center">:</td>
                          <td>12-00-9801-288-91</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td valign="middle" align="center" style={{ fontSize: '30px' }}><strong>Karachi Golf Club</strong></td>
                  </tr>
                  <tr>
                    <td valign="top" align="center"><strong>Habib Ibrahim Rahimtoola Road. Karsaz. P. O. Box No. 2003
                      Karachi-75350</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="center" valign="top"><strong>collection A/C # 0080-900256-01<br />
                      Any Branch of Bank AL-Habib in Pakistan </strong></td>
                    <td align="center" valign="top">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="top">
                      <table width="100%" border="0" cellspacing="0" cellpadding="0" className="normal">
                        <tr>
                          <td rowspan="7" valign="middle" style={{ fontSize: '16px', color: '#B20100', textAlign: 'left' }}><strong>PAYMENT DUE
                            DATE
                            END<sup></sup> OF THE MONTH</strong></td>
                          <td>Membership #: {billDetail?.member_id}</td>
                        </tr>
                        <tr>
                          <td>{billDetail?.member_name}</td>
                        </tr>
                        <tr>
                          <td>{billDetail?.member_address}</td>
                        </tr>
                        <tr>
                          <td>{billDetail?.member_city}</td>
                        </tr>
                        <tr>
                          <td>Postal Code: {billDetail?.member_postal_code}</td>
                        </tr>
                        <tr>
                          <td>Tel : {billDetail?.member_tel_1} {billDetail?.member_tel_2}</td>
                        </tr>
                        <tr>
                          <td>{billDetail?.member_email}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top" style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                      <hr style={{ border: '2px groove #000080' }} />
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" align="left">
                      <table width="600" border="1" cellspacing="0" cellpadding="0" className="report">
                        <tr>
                          <td colspan="3" align="center"><strong>Subscription</strong></td>
                          <td colspan="4" align="center"><strong>Locker</strong></td>
                        </tr>
                        <tr>
                          <td align="center"><strong>Member</strong></td>
                          <td align="center"><strong>Wife</strong></td>
                          <td align="center"><strong>House Hold</strong></td>
                          <td align="center"><strong>Golf Bag</strong></td>
                          <td align="center"><strong>Kit Locker</strong></td>
                          <td align="center"><strong>Ladies</strong></td>
                          <td align="center"><strong>Trolly Fee</strong></td>
                        </tr>
                        <tr>
                          <td align="center">{parseFloat(billDetail?.subs_self).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.subs_wife).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.subs_hh).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.bag_amt).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.cloak_amt).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.lad_amt).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.tr_amt).toFixed(2)}</td>
                        </tr>
                      </table>
                    </td>
                    <td style={{ paddingLeft: '10px' }}><strong>Date:</strong> {moment(billDetail?.created_at).format('DD-MMM-YYYY')}<br />
                      <strong>Billing Month:</strong> {moment(billDetail?.bill_uplaoded_date).format('MMMM YYYY')}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="top">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top">
                      <table width="900" border="0" cellspacing="0" cellpadding="0" className="report">
                        <tr>
                          <td align="center"><strong>Eid Bonus</strong></td>
                          <td align="center"><strong>PGF Fee</strong></td>
                          <td align="center"><strong>SGA Fee</strong></td>
                          <td align="center"><strong>Postage</strong></td>
                          <td align="center"><strong>Absentee</strong></td>
                          <td align="center"><strong>{billDetail?.fixmisc1}</strong></td>
                          <td align="center"><strong>{billDetail?.fixmisc2}</strong></td>
                        </tr>
                        <tr>
                          <td align="center">{parseFloat(billDetail?.gratuity).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.pgf).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.sga).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.postal).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.absentee).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.fixmisc1am).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.fixmisc2am).toFixed(2)}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="top">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top">
                      <table width="900" border="0" cellspacing="0" cellpadding="0" className="report">
                        <tr>
                          <td align="center"><strong>Catering</strong></td>
                          <td align="center"><strong>Catering Adjustment</strong></td>
                          <td align="center"><strong>Caddy Fee</strong></td>
                          <td align="center"><strong>Pro-Shop</strong></td>
                          <td align="center"><strong>Green Fee</strong></td>
                          <td align="center"><strong>Golf Cart</strong></td>
                          <td align="center"><strong>Competition fee</strong></td>
                          <td align="center"><strong>Fitness Center</strong></td>
                        </tr>
                        <tr>
                          <td align="center">{parseFloat(billDetail?.catering).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.phone).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.cadding).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.proshop).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.greenfee).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.golfcart).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.compdon).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.fitness).toFixed(2)}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="top">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top">
                      <table width="900" border="0" cellspacing="0" cellpadding="0" className="report">
                        <tr>
                          <td>&nbsp;</td>
                          <td>{billDetail?.etcmisc1}</td>
                          <td>{billDetail?.etcmisc2}</td>
                          <td align="center"><strong>Surcharge</strong></td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>{parseFloat(billDetail?.etcmisc1am).toFixed(2)}</td>
                          <td>{parseFloat(billDetail?.etcmisc2am).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.gst).toFixed(2)}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="top">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top">
                      <table width="900" border="0" cellspacing="0" cellpadding="0" className="report">
                        <tr>
                          <td><strong>{billDetail?.ex_name}</strong></td>
                          <td align="center"><strong>Entry Fee</strong></td>
                          <td align="center"><strong>Tournament Fund</strong></td>
                          <td align="center"><strong>Late Payment Penalty</strong></td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>{parseFloat(billDetail?.extra).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.entfee).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.levy).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.lps).toFixed(2)}</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" align="center">&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top">
                      <table width="900" border="0" cellspacing="0" cellpadding="0" className="report">
                        <tr>
                          <td align="center"><strong>Total Amount</strong></td>
                          <td align="center"><strong>Adjustment</strong></td>
                          <td align="center"><strong>Previous Balance</strong></td>
                          <td align="center"><strong>Payment Received</strong></td>
                          <td align="center"><span style={{ color: '#B20100' }}><strong>Amount Payable</strong></span></td>
                        </tr>
                        <tr>
                          <td align="center"><strong>{parseFloat(billDetail?.total).toFixed(2)}</strong></td>
                          <td align="center">{parseFloat(billDetail?.adjamt).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.balance).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.amtrec).toFixed(2)}</td>
                          <td align="center"><strong>{parseFloat(billDetail?.net).toFixed(2)}</strong>{billDetail?.net < 0 && <span style={{ color: '#B20100' }}>&nbsp;credit</span>} </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" align="left">&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td valign="top" align="left"><strong style={{ color: '#B20100' }}>FOR INFORMATION</strong></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top">
                      <table width="900" border="0" cellspacing="0" cellpadding="0" className="report">
                        <tr>
                          <td colspan="6"><strong>Actual Bill (Compulsory catering is Rs. 1000.00 per month)</strong></td>
                        </tr>
                        <tr>
                          <td align="center"><strong>Jan/Jul</strong></td>
                          <td align="center"><strong>Feb/Aug</strong></td>
                          <td align="center"><strong>Mar/Sep</strong></td>
                          <td align="center"><strong>Apr/Oct</strong></td>
                          <td align="center"><strong>May/Nov</strong></td>
                          <td align="center"><strong>Jun/Dec</strong></td>
                        </tr>
                        <tr>
                          <td align="center">{parseFloat(billDetail?.month1).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.month2).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.month3).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.month4).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.month5).toFixed(2)}</td>
                          <td align="center">{parseFloat(billDetail?.month6).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td colspan="4"><strong>Difference to make up for compulsory Rs. </strong></td>
                          <td colspan="2"><strong>Total actual catering
                            upto date:</strong> {parseFloat(parseInt(billDetail?.month1) + parseInt(billDetail?.month2) + parseInt(billDetail?.month3) + parseInt(billDetail?.month4) + parseInt(billDetail?.month5) + parseInt(billDetail?.month6)).toFixed(2)}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top" style={{ paddingTop: '4px', paddingBottom: '2px' }}>These
                      funds are intended for Karachi Golf Club (Pvt) Ltd. Collection A/C. # 1003-0980-011860-01-6
                      held with Shahrah-e-Faisal Branch, Karachi.</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="top" style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                      <hr style={{ border: '1px dashed #000080' }} />
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top" style={{ paddingTop: '4px', paddingBottom: '2px' }}>Karachi Golf Club - Collection A/C #
                      0800-900256-01 Any Branch of Bank AL- Habib</td>
                    <td align="right" valign="top" style={{ paddingTop: '2px', paddingBottom: '2px' }}><strong style={{ color: '#B20100' }}>BANK'S
                      COPY</strong></td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top">
                      <table width="900" border="0" cellspacing="0" cellpadding="0" className="report">
                        <tr>
                          <td align="center"><strong>Category </strong><br /></td>
                          <td align="center"><strong>Issue Date</strong></td>
                          <td align="center"><strong>Due Date</strong></td>
                          <td align="center"><strong>Billing Month</strong></td>
                          <td align="center"><strong>Deposited Amount</strong></td>
                        </tr>
                        <tr>
                          <td align="center">{billDetail?.member_category}</td>
                          <td align="center"><span style={{ paddingLeft: '10px' }}>{moment(billDetail?.created_at).format('DD-MMM-YYYY')}</span></td>
                          <td align="center">{moment(billDetail?.created_at).endOf('month').format('DD-MMM-YYYY')}</td>
                          <td align="center"><span style={{ paddingLeft: '10px' }}>{moment(billDetail?.bill_uplaoded_date).format('MMMM YYYY')}</span></td>
                          <td align="center">&nbsp;</td>
                        </tr>
                        <tr>
                          <td align="center"><strong>Membership #</strong></td>
                          <td colspan="3" align="center"><strong>Name</strong></td>
                          <td rowspan="2" align="center">&nbsp;</td>
                        </tr>
                        <tr>
                          <td align="center">{billDetail?.member_id}</td>
                          <td colspan="3" align="center">{billDetail?.member_name}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top"><span style={{ paddingTop: '2px', paddingBottom: '2px' }}>These
                      funds are intended for Karachi Golf Club (Pvt) Ltd. Collection A/C. # 1003-0980-011860-01-6
                      held with Shahrah-e-Faisal Branch, Karachi.</span></td>
                  </tr>
                  <tr>
                    <td valign="top" align="center">&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </table>
              </Box>
            </Box>
          )}

        </Container>
      </Box>

      {/* ========== Footer ========== */}
      <Footer />

    </Box>
  );
}

export default BillDetail;